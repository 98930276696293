import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'reflect-metadata';

import { CircularProgress } from "@mui/material";

import BaseLayout from "./BaseLayout";
import StudentList from "../../screens/student/StudentList";
import ChangePasswordPage from "../../screens/auth/ChangePasswordPage";
import CasesListPage from "../../screens/cases/CasesListPage";
import CreateCasePage from "../../screens/cases/CreateCasePage";
import StudentDetails from "../../screens/student/StudentDetails";
import PaymentsPage from "../../screens/payment/PaymentsPage";
import CaseDetailPage from "../../screens/cases/CaseDetailPage";
import CreatePaymentPage from "../../screens/payment/CreatePaymentPage";
import AgreemantPage from "../../screens/student/AgreemantPage";
import Login from "../../screens/auth/Login";
import PasswordRecovery from "../../screens/auth/PasswordRecovery";
import {UserStore} from "../../screens/auth/UserStore";
import ProfilePage from "../../screens/profile/ProfilePage";
import InstallPwaDialog from "./InstallPwaDialog";
import IosPwaPrompt from "./IosPwaPromt";
import LoginAdmin from "../../screens/staff/LoginAdmin";
import GroupListPage from "../../screens/staff/Group/GroupListPage";
import AdminBaseLayout from "./AdminBaseLayout";
import GroupDetailPage from "../../screens/staff/Group/GroupDetailPage";
import GroupStudentDetail from "../../screens/staff/GroupStudentDetail";
import LessonsPage from "../../screens/staff/Lesson/LossonListPage";
import LessonDetailPage from "../../screens/staff/Lesson/LessonDetailPage";
import CreateLessonPage from "../../screens/staff/Lesson/CreateLessonPage";
import AboutPage from "../../screens/profile/AboutApp";
import PaymentsPageAdmin from "../../screens/staff/Payment/PaymentsPageAdmin";
import PaymentDetailAdmin from "../../screens/staff/Payment/PaymentDetailAdmin";
import CasesListPageAdmin from "../../screens/staff/Case/CaseListPageAdmin";
import CaseDetailPageAdmin from "../../screens/staff/Case/CasePageDetailAdmin";
import WeeklyReportPage from "../../screens/staff/WeeklyReportPage";


const AdminRouter: React.FC = () => {
    const [isAuth, setIsAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initApp = async () => {
            // Предполагается, что initApp асинхронный и обновляет состояние авторизации в UserStore
            const userStore = new UserStore();
            await userStore.initApp();
            setIsAuth(userStore.isAuth);
            setIsLoading(false);
        };

        initApp();
    }, []);

    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}><CircularProgress /></div>;
    }

    return (
        <Router>
            <Routes>
                {isAuth ? (
                    <Route path="/" element={<AdminBaseLayout/>}>
                        {/* Перенаправление с логин и recovery на главную страницу если пользователь авторизован */}
                        <Route path="login" element={<Navigate replace to="/"/>}/>
                        <Route path="password-recovery" element={<Navigate replace to="/"/>}/>
                        <Route index element={<GroupListPage/>}/>
                        <Route path="/" element={<GroupListPage/>}/>
                        <Route path="profile" element={<ProfilePage/>}/>
                        <Route path="students" element={<StudentList/>}/>
                        <Route path="cases" element={<CasesListPageAdmin/>}/>
                        <Route path="changePassword" element={<ChangePasswordPage/>}/>
                        <Route path="cases/:id" element={<CaseDetailPageAdmin/>}/>
                        <Route path="createcase" element={<CreateCasePage/>}/>
                        <Route path="students/:studentId" element={<StudentDetails/>}/>
                        <Route path="payments" element={<PaymentsPageAdmin/>}/>id
                        <Route path="payment/:id" element={<PaymentDetailAdmin/>}/>
                        <Route path="students/:studentId/payments" element={<PaymentsPage/>}/>
                        <Route path="students/:studentId/createpayment" element={<CreatePaymentPage/>}/>
                        <Route path="agreement" element={<AgreemantPage/>}/>
                        <Route path="group" element={<GroupListPage/>}/>GroupDetailPage
                        <Route path="group/:id" element={<GroupDetailPage/>}/>group/students/
                        <Route path="group/student/:id" element={<GroupStudentDetail/>}/>
                        <Route path="lessons" element={<LessonsPage/>}/>
                        <Route path="weeklylessons" element={<WeeklyReportPage/>}/>
                        <Route path="lesson/:lessonId" element={<LessonDetailPage/>}/>
                        <Route path="lesson/create/:lessonId" element={<CreateLessonPage/>}/>
                        <Route path="aboutApp" element={<AboutPage/>}/>



                    </Route>
                ) : (
                    <>
                        <Route path="/login" element={<LoginAdmin/>}/>
                        <Route path="/password-recovery" element={<PasswordRecovery/>}/>
                        <Route path="/agreement" element={<AgreemantPage/>}/>
                        {/* Перенаправление всех остальных путей на страницу входа, если пользователь не авторизован */}
                        <Route path="*" element={<Navigate replace to="/login"/>}/>
                    </>
                )}
            </Routes>
            <InstallPwaDialog/>
            <IosPwaPrompt/>
        </Router>
    );
};

export default AdminRouter;

/*
export default App;

const App: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('home');

  return (

      <Router>
          <Routes>
           /*   <Route path="/" element={<Login />} index /> {/* Автоматический редирект на страницу логина *///}
/*  <Route path="/password-recovery" element={<PasswordRecovery />} />
  <Route path="/students" element={<StudentList />} />
  <Route path="/cases" element={<CasesListPage />} />
  <Route path="/changePassword" element={<ChangePasswordPage />} />
  <Route path="/cases/:id" element={<CaseDetailPage />} />
  <Route path="/createcase" element={<CreateCasePage />} />
  <Route path="/agreement" element={<AgreemantPage />} />
  <Route path="/students/:studentId" element={<StudentDetails />} />
  <Route path="/students/:studentId/payments" element={<PaymentsPage />} />
  <Route path="/students/:studentId/createpayment" element={<CreatePaymentPage />} />
</Routes>
<BottomMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
</Router>
);
};

export default App;

 */

import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Box,
    Chip,
    DialogContent,
    DialogTitle, Dialog, DialogActions
} from '@mui/material';
import ApiService from "../../base/api/ApiService";
import {Payment, PaymentHelper} from "../../components/models/Payment";
import CommonHeader from "../../base/views/CommonHeader";
import {Student} from "../../components/models/Student";
import moment from "moment/moment";
import helper from "../../components/Helper";
import CircularProgressBar from "../../base/views/CircularProgressBar";

interface PaymentProps {
    // studentId теперь является частью пропсов
    studentId: string;
}

const PaymentsPage : React.FC = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [students, setStudents] = useState<Student[]>([]);
    const [student, setStudent] = useState<Student>();
    const { studentId } = useParams<{ studentId: string }>(); // Явное указание типа для studentId
    const [payments, setPayments] = useState<Payment[]>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // Состояние для индикации загрузки


    useEffect(() => {
        const fetchPayments = async () => {
            try {
                const response = await ApiService.getPayments(studentId);
                if(studentId!=null)
                {
                   // console.log("filter");
                   // console.log(response.list.filter(payment=>payment.studentId==studentId));
                    setPayments( response.list.filter(payment=>payment.studentId==studentId));
                 const studentResp = await ApiService.getStudentDetails(studentId);
                 setStudent(studentResp);
                }
                else {
                setPayments(response.list);
                }
            } catch (error) {
                console.error('Ошибка получения списка платежей:', error);
            }finally {
                setIsLoading(false);

            }
        };
        if(studentId==null){
        const fetchStudents = async () => {
            try {
                const studentsData = await ApiService.getStudents();
                setStudents(studentsData);
            } catch (error) {
                console.error('Ошибка при загрузке списка студентов:', error);
            }
            finally {
                setIsLoading(false);

            }
        };
        fetchStudents().then();
        }
        fetchPayments();
    }, []);

    const handlePaymentClick = (paymentId:string) => {
      //  history.push(`/payments/${paymentId}`);
    };

    const handleCreatePayment  = (studentId: string|null) => {
        if(studentId!=null) {
            navigate(`/students/${studentId}/createpayment`);
        }
        else{
            setDialogOpen(true);
        }
    };

    const handleCreatePaymentRequest = async (paymentid:string) => {

        const payRequest = await  ApiService.createPaymentRequest(paymentid as string ,'')
        if(payRequest!=null && payRequest.paymentURL!=null)
        {
            setTimeout(() => {
                window.open(payRequest.paymentURL as string);
            })
        }
    };

            const isPaymentEligible = (payment: Payment): boolean => {
                try {
                    const paymentCreatedAt = moment(payment.createdAt);
                    const twoMonthsAgo = moment().subtract(2, 'months');

                    return (
                        (payment.status === 'canceled' || payment.status === 'created') &&
                        paymentCreatedAt.isSameOrAfter(twoMonthsAgo, 'month') &&
                        payment.paymentType !== 'cash'
                    );
                } catch (error) {
                    console.error('Error in isPaymentEligible:', error);
                    return false;
                }
            };

            const isPaymentCreatable = (student: Student | undefined): Boolean => {
                // Проверяем статус платежа и период оплаты
                if(student===null || student===undefined) return true;
                return student?.isOnlinePay;
            };

            const handleCloseDialog = () => {
                setDialogOpen(false);
            };

          const  handleSelectStudent=async(studentSelectId: string | null)=>{
                  navigate(`/students/${studentSelectId}/createpayment`);
          };

    return (
        <div>
            <CommonHeader title="Оплаты" />
            {isLoading ? (<CircularProgressBar />):(
                <>
            <div style={{margin:'5px'}} >

                <List>
                {payments.map(payment => (
                    <ListItem
                        button
                        key={payment.id}
                        onClick={() => handlePaymentClick(payment.id as string)}
                        sx={{
                            border: 2,
                            borderColor: PaymentHelper.getStatusColor(payment.status as string),
                            borderRadius: '10px',
                            mb: 1, // Добавляет небольшой отступ снизу для каждого элемента
                            '&:hover': {
                                backgroundColor: 'action.hover', // Опционально: изменение фона при наведении
                            }
                        }}
                    >

                        <ListItemText
                            primary={`Сумма: ${payment.summ} - Дата: ${helper.formatTimeWithTimeZone(payment.createdAt as string)}`}
                            secondary={
                                <>
                                    {`Период оплаты: ${PaymentHelper.getMontName(payment.paymentperiod as string)} `}
                                    <Chip
                                        label={PaymentHelper.getStatusName(payment.status as string)}
                                        size="medium"
                                        sx={{ bgcolor: PaymentHelper.getStatusColor(payment.status as string), color: 'white' }}
                                    />
                                </>
                            }
                        />

                        {isPaymentEligible(payment) && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleCreatePaymentRequest(payment.id as string)}
                                sx={{ ml: 2 }}
                            >
                                Оплатить
                            </Button>
                        )}

                    </ListItem>
                ))}
            </List>
            </div>
            {(isPaymentCreatable(student)) &&(
            <Box sx={{ position: 'fixed', bottom: 70, left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
                <Button variant="contained" color="primary"   onClick={() => handleCreatePayment(studentId as string)}>
                    Создать платеж
                </Button>
            </Box>
            )}
            </>)}

            <PaymentDialog open={dialogOpen} onClose={handleCloseDialog} students={students} onSelectStudent={handleSelectStudent} />

        </div>
    );
};


interface PaymentDialogProps {
    open: boolean;
    onClose: () => void;
    students: Student[];

    onSelectStudent: (studentSelectId: string | null) => void; // Функция обратного вызова для передачи выбранного студента

}

const PaymentDialog: React.FC<PaymentDialogProps> = ({ open, onClose, students,onSelectStudent }) => {
    const [selectedStudent, setSelectedStudent] = useState<string | null>(null);

    const handleStudentClick = (student: Student) => {
        if(student.isOnlinePay) {
            console.log("student.id");
            setSelectedStudent(student.id);
            onSelectStudent(student.id);
            onClose();
        }
        else {
            console.log("else");

            onClose();}
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>За кого оплата?</DialogTitle>
            <DialogContent>
                <List>
                    {students.map(student => (

                        <ListItem button key={student.id} onClick={() => handleStudentClick(student)}>
                            <Box>
                                <ListItemText primary={student.name}
                            secondary={`Тариф: ${student.monthlyFee?.toString()} руб/месяц` }
                            />
                                {(!student.isOnlinePay)&&(
                                    <ListItemText
                                        primary={`Оплата только наличными` }
                                />)}

                            </Box>
                        </ListItem>

                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentsPage;
import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { Button, CircularProgress, TextField, Typography, MenuItem } from '@mui/material';
import {Group} from "../../../components/models/Group";
import {Lesson} from "../../../components/models/Lesson";
import ApiService from "../../../base/api/ApiService";
import CircularProgressBar from "../../../base/views/CircularProgressBar";
import CommonHeader from "../../../base/views/CommonHeader";





const CreateLessonPage: React.FC=() => {
    const { lessonId } = useParams<{ lessonId: string }>();
    const [lessonInfo, setLessonInfo] = useState<Lesson>();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState<string>((new Date()).toISOString().slice(0, 16).replace('T', ' '));
    const [endDate, setEndDate] = useState<string>((new Date()).toISOString().slice(0, 16).replace('T', ' '));
    const [groups, setGroups] = useState<Group[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedGroupId, setSelectedGroupId] = useState<string>('');

    useEffect(() => {
        console.log(lessonId);
        if (lessonId === undefined || lessonId === null) {
            fetchGroups();
        } else {
            fetcLesson();
        }
    }, [lessonId]);

    const fetchGroups = async () => {
        setIsLoading(true);
        try {
            const groupsData = await ApiService.getGroups();
            setGroups(groupsData);
            setSelectedGroupId(lessonInfo?.groupId as string);
            console.log(lessonInfo?.groupId);
            console.log("fetchGroups");

        } catch (error) {
            console.error('Ошибка при загрузке списка групп:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetcLesson= async () => {
        try {
            const lessonData = await ApiService.getLesson(lessonId as string);
            setLessonInfo(lessonData);
            console.log("fetchLesson");
            fetchGroups(); // Вызываем fetchGroups после успешного получения урока

        } catch (error) {
            console.error('Ошибка при загрузке', error);
        } finally {
            // Можно выполнить какие-то действия по завершению загрузки урока
        }
    };


    const handleSave = async () => {
        try {
            // Ваш код для выполнения POST-запроса
           await ApiService.createLesson( selectedGroupId, startDate.replace('T', ' '), endDate ); // Пример запроса, замените на ваш
            // Переадресация на страницу "lessons"
            navigate('/lessons');
        } catch (error) {
            console.error('Ошибка при сохранении занятия:', error);
        }
    };

    return (
        <>
        <CommonHeader title={"  Создание занятия"} showBackButton={true} />
        <div style={{ padding: '20px' }}>
            {isLoading ? (
                    <CircularProgressBar />
                ):(<>
            <Typography variant="h4" gutterBottom>
                Создание занятия
            </Typography>
            <TextField
                select
                label="Группа"
                variant="outlined"
                value={selectedGroupId}
                onChange={(e) => setSelectedGroupId(e.target.value)}
                fullWidth

                style={{ marginBottom: '20px' }}
            >
                {groups.map((group) => (
                    <MenuItem key={group.id} value={group.id!.toString()}>
                        {`${group.name}`}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                label="Дата и время начала"
                type="datetime-local"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                style={{ marginBottom: '20px' }}
            />
            <TextField
                label="Дата и время окончания"
                type="datetime-local"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                style={{ marginBottom: '20px' }}
            />

                <Button variant="contained" color="primary" onClick={handleSave}>
                    Сохранить
                </Button>
                </>
            )}
        </div>
        </>
    );
};

export default CreateLessonPage;

import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Collapse,
    Divider,
    Box,
    Chip,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,
} from '@mui/material';
import {Attendance} from "./models/Attendance";
import Helper from "./Helper";

interface AttendanceComponentProps {
    visits: Attendance[];
    onCommentSubmit: (visit: Attendance) => void;
    onVisitSubmit: (visit: Attendance, isVisit:boolean) => void;

}

const AttendanceComponent: React.FC<AttendanceComponentProps> = ({ visits, onCommentSubmit, onVisitSubmit }) => {
    const [expandedItemId, setExpandedItemId] = React.useState<string | null>(null);
    const [comment, setComment] = React.useState<string>('');

    const handleListItemClick = (visitId: string | null) => {
        if (visitId === expandedItemId) {
            setExpandedItemId(null);
        } else {
            setExpandedItemId(visitId);
        }
    };

    const handleIsVisitChangeSubmit = (visit: Attendance, isVisit:boolean) => {
        onVisitSubmit(visit, isVisit);

    };

    const handleCommentSubmit = (visit: Attendance) => {
        // Вызовите функцию обратного вызова для отправки комментария
        onCommentSubmit(visit);
        // Сбросьте состояние комментария и сверните элемент
        setComment('');
        setExpandedItemId(null);
    };
    const getStatusLabel = (visit: Attendance) => {
        const currentTime = new Date().getTime();
        const visitTime = new Date(visit.lesontime as string).getTime();

        if (visitTime > currentTime) {
            return 'Расписание';
        } else {
            return visit.isVisit ? 'Присутствие' : 'Отсутствие';
        }
    };
    const getStatusColor = (visit: Attendance) => {
        const currentTime = new Date().getTime();
        const visitTime = new Date(visit.lesontime as string).getTime();

        if (visitTime > currentTime) {
            return '#e0e0e0'; // Серый
        } else {
            return visit.isVisit ? '#4caf50' : '#f44336'; // Зеленый или красный
        }
    };


    return (
        <List>
            {visits.map((visit) => (
                <div key={visit.id}>
                    <ListItem button onClick={() => handleListItemClick(visit.id)}>
                        <ListItemText
                            primary={`Дата и время: ${Helper.formatTimeWithTimeZone(visit.lesontime as string)}`}
                            secondary={
                                <Chip
                                    label={getStatusLabel(visit)}
                                    style={{ backgroundColor: getStatusColor(visit), color: 'white' }}
                                />
                            }
                        />
                    </ListItem>
                    <Collapse in={visit.id === expandedItemId} timeout="auto" unmountOnExit>
                        <Divider />
                        <Box p={2}>
                            <FormControlLabel
                                control={<Checkbox checked={visit.isVisit as boolean} onChange={(e) =>handleIsVisitChangeSubmit(visit,e.target.checked ) } />}
                                label="Будет на занятии"
                            />
                            <TextField
                                label="Комментарий"
                                variant="outlined"
                                fullWidth
                                value={visit.commentClient}
                                onChange={(e) => setComment(visit.commentClient=e.target.value)}
                                multiline
                                rows={4}
                                sx={{ marginBottom: '3px' }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleCommentSubmit(visit)}
                            >
                                Отправить
                            </Button>
                        </Box>
                    </Collapse>
                    <Divider />
                </div>
            ))}
        </List>
    );
};

export default AttendanceComponent;

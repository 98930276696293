import React, {useEffect, useState} from 'react';
import Circle from './Circle';
import {Attendance} from "./models/Attendance";
import {useParams} from "react-router-dom";
import {Lesson} from "./models/Lesson";
import ApiService from "../base/api/ApiService";

interface VisitsDisplayProps {
    studentId: string;
}

const VisitsDisplay: React.FC<VisitsDisplayProps> = ({studentId}) => {

    const [attendances, setAttendances] = useState<Attendance[]>([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            try {

                const attendances = await  ApiService.getStudentVisits(studentId as string, 15 );
                setAttendances(attendances);
                console.log(attendances);
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
            }
        };

        fetchData();
    }, [studentId]);

    return (
        <div  style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            {attendances.map((visit, index) => (
                <Circle key={index} date={visit.lesontime!} isVisit={visit.isVisit!} />
            ))}
        </div>
    );
};

export default VisitsDisplay;

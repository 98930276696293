import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    MenuItem, Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import ApiService from "../../../base/api/ApiService";
import CommonHeader from "../../../base/views/CommonHeader";
import {PaymentHelper} from "../../../components/models/Payment";
import CircularProgressBar from "../../../base/views/CircularProgressBar";
import helper from "../../../components/Helper";

const PaymentDetailAdmin: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [payment, setPayment] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [editable, setEditable] = useState(false);
    const [paymentPeriod, setPaymentPeriod] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentType, setPaymentType] = useState('');

    useEffect(() => {
        const fetchPayment = async () => {
            setIsLoading(true);
            try {
                const paymentData = await ApiService.getPayment(id!);
                setPayment(paymentData);
                setPaymentPeriod(paymentData.paymentperiod as string);
                setPaymentStatus(paymentData.status as string);
                setPaymentType(paymentData.paymentType as string);
                setIsLoading(false);
            } catch (error) {
                console.error('Ошибка при загрузке платежа:', error);
                setIsLoading(false);
            }
        };
        setEditable(ApiService.getPaymentACL());
        fetchPayment();
    }, [id]);

    const handleSave = async () => {
        setIsSaving(true);
        try {
            // Отправить данные на сервер для обновления платежа
           const paymentUpdated = await ApiService.updatePayment(id as string, {
                paymentperiod: paymentPeriod,
                status: paymentStatus,
                paymentType: paymentType
            });
            // Обновить состояние платежа
            if(paymentUpdated!==null) {
                setPayment(paymentUpdated);
            }

        } catch (error) {
            console.error('Ошибка при сохранении платежа:', error);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div>
            <CommonHeader title="Детали платежа" showBackButton={true} />
            {isLoading ? (
                <CircularProgressBar />
            ) : (
                <Box p={2}>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>

                                <TableRow>
                                    <TableCell>Имя клиента:</TableCell>
                                    <TableCell>
                                        <Typography variant="body2">{payment.clientName}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Имя студента:</TableCell>
                                    <TableCell>
                                        <Typography variant="body2">{payment.studentName}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Сумма платежа:</TableCell>
                                    <TableCell>
                                        <Typography variant="h6">{payment.summ}</Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Статус:</TableCell>
                                    <TableCell>
                                        <Box>
                                            {editable ? (
                                                <TextField
                                                    select
                                                    value={paymentStatus}
                                                    onChange={(e) => setPaymentStatus(e.target.value)}
                                                >
                                                    {Object.entries(PaymentHelper.status).map(([key, value]) => (
                                                        <MenuItem key={key} value={key}>{value}</MenuItem>
                                                    ))}
                                                </TextField>
                                            ) : (
                                                <Typography>{payment.status}</Typography>
                                            )}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Период платежа:</TableCell>
                                    <TableCell>
                                        {editable ? (
                                            <TextField
                                                select
                                                value={paymentPeriod}
                                                onChange={(e) => setPaymentPeriod(e.target.value)}
                                            >
                                                {Object.entries(PaymentHelper.months).map(([key, value]) => (
                                                    <MenuItem key={key} value={key}>{value}</MenuItem>
                                                ))}
                                            </TextField>
                                        ) : (
                                            <Typography>{payment.paymentperiod}</Typography>
                                        )}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Дата создания:</TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{helper.formatTimeWithTimeZone(payment.createdAt as string)}</Typography>

                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Создано:</TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{payment.createdByName}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Дата изменения:</TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{helper.formatTimeWithTimeZone(payment.modifiedAt as string)}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Изменено:</TableCell>
                                    <TableCell>
                                        <Typography variant="body1">{payment.modifiedByName || 'Нет данных'}</Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Тип платежа:</TableCell>
                                    <TableCell>
                                        <Box> {editable ? (
                                            <TextField
                                                select
                                                value={paymentType}
                                                onChange={(e) => setPaymentType(e.target.value)}
                                            >
                                                {Object.entries(PaymentHelper.paymentTypes).map(([key, value]) => (
                                                    <MenuItem key={key} value={key}>{value}</MenuItem>
                                                ))}
                                            </TextField>
                                        ) : (
                                            <Typography>{payment.paymentType}</Typography>
                                        )}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {editable && (
                        <Box textAlign="center" mt={4}>
                        <Button variant="contained" color="primary"  onClick={handleSave} disabled={isSaving}>Сохранить</Button>
                        </Box>
                    )}
                </Box>
            )}
        </div>
    );
};

export default PaymentDetailAdmin;

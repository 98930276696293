// CasesListPage.tsx
import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {List, ListItem, ListItemText, Chip, Typography, CircularProgress, Box, Button} from '@mui/material';
import ApiService from "../../../base/api/ApiService";
import {Case,CaseHelper} from "../../../components/models/case";

import {Nullable} from "../../../components/models/BaseTypes";
import CommonHeader from "../../../base/views/CommonHeader";


const CasesListPageAdmin: React.FC = () => {
    const [cases, setCases] = useState<Case[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchCases = async () => {
            try {
                const response = await ApiService.getCases();
                setCases(response);
            } catch (error) {
                console.error('Ошибка получения списка обращений:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCases();
    }, []);

    const handleCreateCase = () => {
        navigate(`/createcase`);
    };
    const getStatusValue = (key: keyof typeof CaseHelper.status) => {
        return CaseHelper.status[key] || key;
    };


    return (
        <div>
            <CommonHeader title="Обращения" />
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <List>
                    {cases.map((caseItem) => (
                        <ListItem key={caseItem.id} button component={Link} to={`/cases/${caseItem.id}`}>
                           <Box>
                            <ListItemText
                                primary={CaseHelper.getTypeName(caseItem?.type ?? "")}
                                secondary={`Тема: ${caseItem.subject} - Дата: ${caseItem.createdAt}`}
                            />
                               <ListItemText
                                   secondary={`От: ${caseItem.contactName}`}
                               />
                           </Box>

                            <Chip
                                label={getStatusValue(caseItem.status as keyof typeof CaseHelper.status)}
                                sx={{
                                    marginLeft: 'auto',
                                    backgroundColor: CaseHelper.getStatusColor(caseItem.status as string),
                                    color: "#FAFAFA"
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            )}

            <Box sx={{ position: 'fixed', bottom: 70, left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
                <Button variant="contained" color="primary"   onClick={() => handleCreateCase()}>
                    Создать обращение
                </Button>
            </Box>
        </div>
    );
};

export default CasesListPageAdmin;

import React from 'react';

interface CircleProps {
    date: string;
    isVisit: boolean;
}

const Circle: React.FC<CircleProps> = ({ date, isVisit }) => {
    const style: React.CSSProperties = {
        display: 'flex',
        flex: '0 0 auto',
        alignItems: 'center',
        justifyContent: 'center',
        width: '60px',
        height: '60px',
        borderRadius: '30px', // Половина от ширины и высоты
        backgroundColor: isVisit ? 'green' : 'red',
        color: 'white',
        margin: '6px'
    };

    const formatDate = (dateString: string): string => {
        const d = new Date(dateString);
        return `${d.getDate()} 
         ${d.toLocaleString('ru-Ru', { weekday: 'short' })}`;
    };

    return (
        <div style={style}>
            {formatDate(date)}
        </div>
    );
};

export default Circle;
import React, { useState, useEffect } from 'react';
import { Box, Chip, IconButton, List, ListItem, ListItemText, TextField, Autocomplete, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ApiService from "../../../base/api/ApiService";
import { Payment, PaymentHelper } from "../../../components/models/Payment";
import CommonHeader from "../../../base/views/CommonHeader";
import CircularProgressBar from "../../../base/views/CircularProgressBar";
import helper from "../../../components/Helper";
import { useNavigate } from 'react-router-dom';

const PaymentsPageAdmin: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [payments, setPayments] = useState<Payment[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [paymentPeriod, setPaymentPeriod] = useState<string[]>([PaymentHelper.getMounthCurrentKey() as string]);
    const [paymentStatus, setPaymentStatus] = useState<string[]>(["succeeded"]);
    const navigate = useNavigate();

    const fetchPayments = async () => {
        setIsLoading(true);
        try {
            let pagesize = 150;
            const payments = await ApiService.getPaymentByFilter2(
                paymentStatus, "", paymentPeriod, 10, 1, pagesize, searchTerm
            );
            setPayments(payments.list);
        } catch (error) {
            console.error('Ошибка при загрузке списка оплат:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setPaymentPeriod([PaymentHelper.getMounthCurrentKey() as string]);
        setPaymentStatus(["succeeded"]);
        fetchPayments();
    }, []);

    const handleSearch = async () => {
        setPayments([]);
        await fetchPayments();
    };

    const handlePeriodChange = (event: any, values: { key: string, value: string }[]) => {
        setPaymentPeriod(values.map(item => item.key));
    };

    const handleStatusChange = (event: any, values: { key: string, value: string }[]) => {
        setPaymentStatus(values.map(item => item.key));
    };

    const handlePaymentClick = (id: string) => {
        navigate(`/payment/${id}`)
    };

    const paymentPeriodOptions = Object.entries(PaymentHelper.months).map(([key, value]) => ({ key, value }));
    const paymentStatusOptions = Object.entries(PaymentHelper.status).map(([key, value]) => ({ key, value }));

    return (
        <div>
            <CommonHeader title="Оплаты" showBackButton={true} />
            <div style={{ marginTop: "6px", marginRight: "4px", marginLeft: "4px", marginBottom: "4px" }}>
                {/* Поиск */}
                <Box sx={{margin:"4px", display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, mt: 2 }}>
                <TextField
                    label="Поиск"
                    variant="outlined"
                    value={searchTerm}
                    sx={{ margin: '5px', width: "94%", alignSelf: "center" }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <IconButton onClick={handleSearch}>
                    <SearchIcon color="primary" fontSize="large" />
                </IconButton>
                </Box>

                <Box sx={{ margin: "4px", display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mt: 2 }}>
                    {/* Выпадающий список для периода оплаты */}
                    <Autocomplete
                        multiple
                        sx={{ margin: '5px', width: "100%" }}

                        options={paymentPeriodOptions}
                        disableCloseOnSelect
                        value={paymentPeriodOptions.filter(option => paymentPeriod.includes(option.key))}
                        onChange={handlePeriodChange}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    checked={paymentPeriod.includes(option.key)}
                                    style={{ marginRight: 8 }}
                                />
                                {option.value}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Период оплаты" sx={{ width: { xs: '100%', sm: '100%' } }} />
                        )}
                    />
                    {/* Выпадающий список для статуса оплаты */}
                    <Autocomplete
                        multiple
                        sx={{ margin: '5px', width: "100%" }}

                        options={paymentStatusOptions}
                        disableCloseOnSelect
                        value={paymentStatusOptions.filter(option => paymentStatus.includes(option.key))}
                        onChange={handleStatusChange}
                        getOptionLabel={(option) => option.value}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    checked={paymentStatus.includes(option.key)}
                                    style={{ marginRight: 8 }}
                                />
                                {option.value}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Статус" sx={{ width: { xs: '100%', sm: '100%' } }} />
                        )}
                    />

                </Box>
                {/* Список оплат */}
                {isLoading ? (
                    <div><CircularProgressBar /></div>
                ) : (
                    <List>
                        {payments.map(payment => (
                            <ListItem
                                button
                                key={payment.id}
                                onClick={() => handlePaymentClick(payment.id as string)}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    border: 2,
                                    alignItems: 'flex-start',
                                    borderColor: PaymentHelper.getStatusColor(payment.status as string),
                                    borderRadius: '10px',
                                    mb: 1,
                                    '&:hover': {
                                        backgroundColor: 'action.hover',
                                    }
                                }}
                            >
                                <ListItemText
                                    primary={`${payment.clientName} `}
                                    secondary={
                                        <>
                                            {`Оплата за: ${(payment.studentName)} `}
                                        </>
                                    }
                                />
                                <ListItemText
                                    primary={`Сумма: ${payment.summ}`}
                                    secondary={
                                        <>
                                            <Chip
                                                label={PaymentHelper.getStatusName(payment.status as string)}
                                                size="medium"
                                                sx={{ bgcolor: PaymentHelper.getStatusColor(payment.status as string), color: 'white' }}
                                            />
                                            <Chip
                                                label={PaymentHelper.getMontName(payment.paymentperiod as string)}
                                                size="medium"
                                                sx={{ bgcolor: '#4caf50', color: 'white' }}
                                            />
                                            {`   Дата: ${helper.formatTimeWithTimeZone(payment.modifiedAt as string)}`}
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </div>
        </div>
    );
};

export default PaymentsPageAdmin;

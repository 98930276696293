import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, Box, Chip, TextField, MenuItem, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ApiService from "../../../base/api/ApiService";
import { Case, CaseHelper } from "../../../components/models/case";
import CommonHeader from "../../../base/views/CommonHeader";
import {Nullable} from "../../../components/models/BaseTypes";
import {PaymentHelper} from "../../../components/models/Payment";
import {red} from "@mui/material/colors";

const CaseDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [caseDetail, setCaseDetail] = useState<Case | null>(null);
    const [loading, setLoading] = useState(true);
    const [editableStatus, setEditableStatus] = useState('');
    const [editableAnswer, setEditableAnswer] = useState('');
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        const fetchCaseDetail = async () => {
            try {
                const response = await ApiService.getCaseDetail(id as string);
                setCaseDetail(response);
                const statusKeys = Object.keys( CaseHelper.status); // Получаем ключи месяцев

                setEditableStatus(response.status as string);
                setEditableAnswer(response.ansver as string);
            } catch (error) {
                console.error('Ошибка получения подробной информации обращения:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCaseDetail();
    }, [id]);

    const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        console.log(event.target.value as string);
        setEditableStatus(event.target.value as string);
        setIsEdited(true);
    };

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditableAnswer(event.target.value);
        setIsEdited(true);
    };

    const handleSaveChanges = async () => {
        if (caseDetail) {
            try {
                await ApiService.updateCase(caseDetail.id as string, {

                    status: editableStatus,
                    ansver: editableAnswer
                });
                setIsEdited(false);
            } catch (error) {
                console.error('Ошибка при сохранении изменений:', error);
            }
        }
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'New':
                return 'default';
            case 'Assigned':
                return 'warning';
            case 'Pending':
                return 'warning';
            case 'Closed':
                return "success";
            case 'Rejected':
                return "error";
            case 'Duplicate':
                return "error";
            default:
                return 'default';
        }
    };


    const getTextColor = (status: string) => {
        switch (status) {
            case 'New':
                return '#FF0000';
            case 'Pending':
                return '#f3c504';
            case 'InProgress':
                return '#FF0000';
            case 'Closed':
                return '#0a6203';
            case 'Rejected':
                return '#FF0000';
            case 'Duplicate':
                return '#FF0000';
            default:
                return 'primary';
        }
    };



    return (
        <div>
            <CommonHeader title="Обращение" showBackButton={true} />
            <Box margin={1}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <Typography variant="h5">{CaseHelper.getTypeName(caseDetail?.type ?? "")}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1">Дата: {caseDetail?.createdAt}</Typography>

                            <TextField
                                select
                                label=""
                                value={editableStatus}
                                onChange={handleStatusChange}
                                variant="outlined"
                                sx={{
                                    marginLeft: 'auto',
                                    minWidth: 130,
                                    borderRadius: 20,
                                    backgroundColor: getStatusColor(editableStatus),
                                    borderColor:getTextColor(editableStatus),

                                    color: getTextColor(editableStatus),
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 20,
                                        borderColor:getTextColor(editableStatus),
                                        color: getTextColor(editableStatus)
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: getTextColor(editableStatus),
                                    },

                                }}
                            >
                                {Object.entries(CaseHelper.status).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>{value}</MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Typography variant="body1">От: {caseDetail?.contactName}</Typography>
                        <Typography variant="h6">Тема: {caseDetail?.subject}</Typography>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ padding: 1.2, width: '80%', border: 1, borderRadius: 3 }}>
                                <Typography variant="h6">Вопрос:</Typography>
                                <Typography variant="body1">{caseDetail?.description}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', position: 'relative' }}>
                            <Box sx={{ padding: 1.2, width: '80%', border: 1, borderRadius: 3 }}>
                                <Typography variant="h6">Ответ:</Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    variant="outlined"
                                    value={editableAnswer}
                                    onChange={handleAnswerChange}
                                    placeholder="Введите ответ"
                                />
                            </Box>
                            {isEdited && (
                                <IconButton
                                    color="primary"
                                    onClick={handleSaveChanges}
                                    sx={{ position: 'absolute', bottom: 4, right: 5, fontSize: 22 }}
                                >
                                    <SendIcon style={{ fontSize: 35 }} />
                                </IconButton>
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </div>
    );
};

export default CaseDetailPage;

import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, List, ListItem, ListItemText, TextField, MenuItem } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Group } from "../../../components/models/Group";
import ApiService from "../../../base/api/ApiService";
import CommonHeader from "../../../base/views/CommonHeader";
import CircularProgressBar from "../../../base/views/CircularProgressBar";
import { Facility } from "../../../components/models/Facility";

const GroupsListPage: React.FC = () => {
    const [groups, setGroups] = useState<Group[]>([]);
    const [facilities, setFacilities] = useState<Facility[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedFacility, setSelectedFacility] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await ApiService.getGroups(null);
                const responseFacilities = await ApiService.getFacilityes();
                setGroups(response);
                setFacilities(responseFacilities);
                const defaultFacility = localStorage.getItem('defaultFacility');
                if (defaultFacility) {
                    setSelectedFacility(defaultFacility);

                }
                setLoading(false);
            } catch (error) {
                console.error('Ошибка получения списка групп:', error);
                setLoading(false);
            }
        };

        fetchGroups();
    }, []);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleFacilityChange = (event: React.ChangeEvent<{ value: string }>) => {
        const facility = event.target.value as string;
        setSelectedFacility(facility);
        localStorage.setItem('defaultFacility', facility);
    };

    const handleGroupClick = (groupId: string) => {
        navigate(`/group/${groupId}`);
    };

    const filteredGroups = groups.filter((group) =>
        group.name!.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (!selectedFacility || group.facilityId === selectedFacility)
    );

    const handleCalendarClick =()=>
    {
        navigate(`/weeklylessons`);
    }

    return (
        <div>
            <CommonHeader title="Группы" showCalendarButton={true} onCalendarButtonClick={handleCalendarClick} />
            <TextField
                label="Поиск"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearch}
                style={{ marginBottom: '20px', marginLeft: '12px', marginRight: '12px', marginTop: '10px', width: '90%' }}
            />
            <TextField
                select
                label="Филиал"
                variant="outlined"
                value={selectedFacility || ''}
                onChange={handleFacilityChange}
                style={{ marginBottom: '20px', marginLeft: '12px', marginRight: '12px', width: '90%' }}
            >
                <MenuItem value="">Все</MenuItem>
                {facilities.map((facility) => (
                    <MenuItem key={facility.id as string} value={facility.id!}>
                        {facility.name}
                    </MenuItem>
                ))}
            </TextField>
            {loading ? (
                <CircularProgressBar />
            ) : (
                <List>
                    {filteredGroups.map((group) => (
                        <ListItem
                            key={group.id}
                            onClick={() => handleGroupClick(group.id as string)}
                            style={{ textDecoration: 'none', color: 'inherit', fontSize: '1.2em' }}
                        >
                            <ListItemText primary={group.name} secondary={`Филиал: ${group.facilityName}`} />
                        </ListItem>
                    ))}
                </List>
            )}
        </div>
    );
};

export default GroupsListPage;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Group';
import ListIcon from '@mui/icons-material/List';
import PaymentIcon from '@mui/icons-material/Payment';
import CalendarIcon from '@mui/icons-material/CalendarMonthSharp';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ApiService from "../api/ApiService";

const AdminBottomMenu = () => {
    const navigate = useNavigate();
    // Допустим, что вы храните текущий выбранный путь в URL,
    // и используете его для определения активной вкладки
    const currentPath = window.location.pathname;
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        navigate(`/${newValue}`); // Используйте navigate для изменения URL
    };

    return (
        <Box sx={{ pb: 7 }}>
            <BottomNavigation value={currentPath.replace('/', '')} onChange={handleChange} sx={{ position: 'fixed', bottom: 0, width: '100%' }}>
                <BottomNavigationAction label="Главная" value="group" icon={<HomeIcon />} />
                <BottomNavigationAction label="Занятия" value="lessons" icon={<ListIcon />} />
                <BottomNavigationAction label="Обращения" value="cases" icon={<ContactSupportIcon />} />
                { ApiService.getACL()?.table?.Payment?.read==="all" ? (
                    <BottomNavigationAction label="Оплаты" value="payments" icon={<PaymentIcon />} />
                    ):null}
                <BottomNavigationAction label="Профиль" value="profile" icon={<AccountCircleIcon />} />
            </BottomNavigation>
        </Box>
    );
};

export default AdminBottomMenu;

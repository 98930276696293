import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Typography,
    Button, TextField, Chip,
    Tab, Tabs, Box, MenuItem,
} from '@mui/material';
import { Lesson } from "../../../components/models/Lesson";
import ApiService from "../../../base/api/ApiService";
import helper from "../../../components/Helper";
import CircularProgressBar from "../../../base/views/CircularProgressBar";
import CommonHeader from "../../../base/views/CommonHeader";
import moment from 'moment';
import { format, isToday, isBefore } from 'date-fns';
import {Facility} from "../../../components/models/Facility";
import {Group} from "../../../components/models/Group";


const LessonsPage: React.FC = () => {
    const [lessons, setLessons] = useState([] as Lesson[]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();
    const [facilities, setFacilities] = useState<Facility[]>([]);
    const [selectedFacility, setSelectedFacility] = useState<string | null>(null);
    const [groups, setGroups] = useState<Group[]>([]);




    useEffect(() => {
        const fetchLessons = async () => {
            setIsLoading(true); // Устанавливаем флаг загрузки
            setTabValue(1);
            try {
                const date1 = new Date();
                date1.setDate(date1.getDate() - 10);
                const formattedDate1 = date1.toISOString().slice(0, 10);

                const today = new Date();
                today.setDate(today.getDate() + 14);
                const formattedToDate = today.toISOString().slice(0, 10);

                const lessonsData = await ApiService.getLessons(formattedDate1, formattedToDate);
                setLessons(lessonsData);

                const responseFacilities = await ApiService.getFacilityes();
                setFacilities(responseFacilities);

                const defaultFacility = localStorage.getItem('defaultFacility');
                if (defaultFacility) {
                    setSelectedFacility(defaultFacility);

                }
                const response = await ApiService.getGroups(null);
                setGroups(response);

                setIsLoading(false); // Снимаем флаг загрузки после успешной загрузки данных
            } catch (error) {
                console.error('Error fetching lessons:', error);
                setIsLoading(false); // Снимаем флаг загрузки в случае ошибки
            }
        };

        fetchLessons();
    }, []);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const filteredLessons = lessons.filter((lesson) =>
        lesson.name!.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredGroups = groups.filter((group) =>
        (!selectedFacility || group.facilityId === selectedFacility)
    );

    const getChipColor = (lesson: Lesson) => {
        if (isToday(new Date(lesson.lessontime!))) {
            return 'green';
        } else if (isBefore(new Date(lesson.lessontime!), new Date())) {
            return 'gray';
        } else {
            return 'gray';
        }
    };

    const getChipLabel = (lesson: Lesson) => {
        if (isToday(new Date(lesson.lessontime!))) {
            return 'Сегодня';
        } else if (isBefore(new Date(lesson.lessontime!), new Date())) {
            return 'Прошло';
        } else {
            return 'План';
        }
    };

    const  getLocalTime=(date:string)=>
    {
        var day = moment.utc(date).local().format("ddd");
        console.log(day);
        var local = moment.utc(date).local().format("HH:mm");
        return local;
    };
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleFacilityChange = (event: React.ChangeEvent<{ value: string }>) => {
        const facility = event.target.value as string;
        setSelectedFacility(facility);
        localStorage.setItem('defaultFacility', facility);
    };

    const groupIds = filteredGroups.map(group => group.id);

    console.log(groupIds);
    let filteredList: Lesson[] = [];
    if (tabValue === 0) {
        filteredList = lessons.filter(lesson => (isBefore(new Date(lesson.lessontime!), new Date()) && !isToday(new Date(lesson.lessontime!)) && groupIds.includes(lesson.groupId))).sort((a, b) => new Date(a.lessontime!).getTime() - new Date(b.lessontime!).getTime());;
    } else if (tabValue === 1) {
        console.log("isToday");
        filteredList = lessons.filter(lesson => (isToday(new Date(lesson.lessontime!)) && groupIds.includes(lesson.groupId))).sort((a, b) => new Date(a.lessontime!).getTime() - new Date(b.lessontime!).getTime());;
    } else if (tabValue === 2) {
        filteredList = lessons.filter(lesson => !isBefore(new Date(lesson.lessontime!), new Date()) && groupIds.includes(lesson.groupId) && !isToday(new Date(lesson.lessontime!)))
            .sort((a, b) => new Date(a.lessontime!).getTime() - new Date(b.lessontime!).getTime());
    }

    const handleCalendarClick =()=>
    {
        navigate(`/weeklylessons`);
    }

    return (
        <>
            <CommonHeader title="Занятия" showCalendarButton={true} onCalendarButtonClick={handleCalendarClick} />

            <TextField
                label="Поиск"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearch}
                style={{ marginBottom: '20px', marginLeft:'12px', marginRight:'12px', marginTop:'10px', width:'90%'}}
            />
            <TextField
                select
                label="Филиал"
                variant="outlined"
                value={selectedFacility || ''}
                onChange={handleFacilityChange}
                style={{ marginBottom: '20px', marginLeft: '12px', marginRight: '12px', width: '90%' }}
            >
                <MenuItem value="">Все</MenuItem>
                {facilities.map((facility) => (
                    <MenuItem key={facility.id as string} value={facility.id!}>
                        {facility.name}
                    </MenuItem>
                ))}
            </TextField>

            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered

            >
                <Tab label="Прошедшие" />
                <Tab label="Сегодня" />
                <Tab label="Планируемые" />
            </Tabs>
            <List>
                {filteredList.map((lesson) => (
                    <ListItem key={lesson.id} button component={Link} to={`/lesson/${lesson.id}`}>
                        <ListItemText
                            primary={<Typography variant="h5">{lesson.groupName}</Typography>}
                            secondary={<Typography variant="h6">{`${helper.formatTimeWithTimeZone(lesson.lessontime as string)} - ${getLocalTime(lesson.endtime!)}`}</Typography>}
                        />
                        <Chip label={getChipLabel(lesson)} style={{ backgroundColor: getChipColor(lesson), color: 'white' }} />
                    </ListItem>
                ))}
            </List>
            {isLoading && <CircularProgressBar />}
        </>
    );
};

export default LessonsPage;

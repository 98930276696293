import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { CircularProgress, Chip, Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import {Student} from "../../components/models/Student";
import {Attendance} from "../../components/models/Attendance";
import ApiService from "../../base/api/ApiService";
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WifiCalling3TwoToneIcon from '@mui/icons-material/WifiCalling3TwoTone';
import CircularProgressBar from "../../base/views/CircularProgressBar";
import CommonHeader from "../../base/views/CommonHeader";
import AttendanceComponent from "../../components/AttendanceComponent";
import {Payment} from "../../components/models/Payment";
import PaymentsComponent from "../../components/PaymentsConponent";




const GroupStudentDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [student, setStudent] = useState<Student | null>(null);
    const [visits, setVisits] = useState<Attendance[]>([]);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();


    useEffect(() => {
        try {

            const fetchStudentDetails = async () => {
                try {
                    const studentData = await ApiService.getStudentDetails(id!);
                    setStudent(studentData);
                } catch (error) {
                    console.error('Ошибка получения данных о студенте:', error);
                }
            };
            const fetchPayments = async () => {
                try {
                    const paymentsData = await ApiService.getPayments(id!);
                    setPayments(paymentsData.list);
                } catch (error) {
                    console.error('Ошибка получения данных о студенте:', error);
                }
            };

            const fetchVisits = async () => {
                try {
                    const today = new Date(); // Получаем текущую дату
                    today.setDate(today.getDate() - 30); // Отнимаем 4 дня
                    const formattedDate = today.toISOString().slice(0, 10);
                    const visitsData = await ApiService.getStudentVisits(id!, 30);
                    setVisits(visitsData);
                } catch (error) {
                    console.error('Ошибка получения списка посещений:', error);
                    setLoading(false);
                }
            };
            fetchStudentDetails();
            fetchVisits();
            fetchPayments();
        }
        catch (error)
        { console.error('Ошибка получения списка посещений:', error);}
        finally {
            setLoading(false);

        }

    }, [id]);

    const getStatusColor = (visit: Attendance): string => {
        const currentTime = new Date().getTime();
        const visitTime = new Date(visit.lesontime as string).getTime();

        if (visitTime > currentTime) {
            return '#e0e0e0'; // Серый (если занятие еще не прошло)
        } else {
            return visit.isVisit ? '#4caf50' : '#f44336'; // Зеленый (присутствовал) или красный (отсутствовал)
        }
    };
    const handleCommentSubmit = async (visit: Attendance) => {
        try {
            const attendance = await  ApiService.setStudentVisit(visit.id as string,visit);
            // Здесь вызовите вашу функцию API для отправки комментария
            console.log('Отправка комментария для посещения:', visit);
        } catch (error) {
            console.error('Ошибка при отправке комментария:', error);
        }
    };

    const setIsVisit = (visit: Attendance, isVisit: boolean) => {
        setVisits(prevVisits => {
            const updatedVisits = prevVisits.map(prevVisit => {
                if (prevVisit.id === visit.id) {
                    return { ...prevVisit, isVisit };
                } else {
                    return prevVisit;
                }
            });
            return updatedVisits;
        });
    };
    const handleCall = (phoneNumber: string) => {
        console.log('Звонок по номеру:', phoneNumber);
        // Здесь можно добавить логику для совершения звонка
    };

    if (loading || !student) {
        return <CircularProgressBar />;
    }


    const handlePaymentClick =  (id: string) => {
        navigate(`/payment/${id}`);
    }


    return (
        <div>
            <CommonHeader title={"Ученик"} showBackButton={true}/>
            <div style={{ margin: '6px' }}>
            <Typography variant="h5">{student.name}</Typography>
            <Typography variant="body1">Телефон: {student.studentPhone}</Typography>
            <Typography variant="body1">Дата рождения: {student.birthday}</Typography>

            <Button startIcon={<PhoneIcon />} href= {`tel:${student.studentPhone}`}>Позвонить</Button>
            <Button startIcon={<WhatsAppIcon />} href={`https://wa.me/${student.studentPhone}`}>WhatsApp</Button>
            <Button startIcon={<WifiCalling3TwoToneIcon />} href={`viber://chat/?number=%2B${student.studentPhone?.replace('+','')}`}>Viber</Button>

            <Typography variant="h5">Представитель</Typography>
            <Typography variant="body1">ФИО родителя: {student.clientName}</Typography>
            <Typography variant="body1">Телефон: {student.clientPhone}</Typography>
            <Button startIcon={<PhoneIcon />} href= {`tel:${student.clientPhone}`}>Позвонить</Button>
            <Button startIcon={<WhatsAppIcon />} href={`https://wa.me/${student.clientPhone}`}>WhatsApp</Button>
            <Button startIcon={<WifiCalling3TwoToneIcon />} href={`viber://chat/?number=%2B${student.clientPhone?.replace('+','')}`}>Viber</Button>
            </div>
            <AttendanceComponent visits={visits} onCommentSubmit={handleCommentSubmit} onVisitSubmit={setIsVisit}/>
            {//<PaymentsComponent payments={payments} onPaymentClick={handlePaymentClick}/>
            }
        </div>
    );
};

export default GroupStudentDetailPage;

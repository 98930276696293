import BottomMenu from "./BottomMenu";
import React, {useState} from "react";
import {Outlet} from "react-router-dom";
import AdminBottomMenu from "./AdminBottomMenu";

interface IBaseLayoutProps {

}

const AdminBaseLayout: React.FC<IBaseLayoutProps> = () => {

    const [selectedTab, setSelectedTab] = useState<string>('home');
    return (
        < >
            <Outlet />
            <AdminBottomMenu />
        </>
    );
};

export default AdminBaseLayout;

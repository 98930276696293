import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Box, Button,
    CircularProgress,
    List,
    ListItem,
    ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Student} from "../../../components/models/Student";
import {Schedule} from "../../../components/models/Schedule";
import ApiService from "../../../base/api/ApiService";
import {Group} from "../../../components/models/Group";
import CommonHeader from "../../../base/views/CommonHeader";
import CircularProgressBar from "../../../base/views/CircularProgressBar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import WifiCalling3TwoToneIcon from "@mui/icons-material/WifiCalling3TwoTone";



const GroupInfo: React.FC<{ groupDetail: Group, studentCount:number }> = ({ groupDetail, studentCount }) => (
    <div>
        <Typography variant="h4" gutterBottom>
            {groupDetail.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
            Филиал: {groupDetail.facilityName}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
            Количество учеников: {studentCount}
        </Typography>
    </div>
);

const ScheduleAccordion: React.FC<{ schedule: Schedule[] }> = ({ schedule }) => {
    const cellStyles = {
        fontSize: '1.15rem',
    };
    const cellStylesSubtitle = {
        fontSize: '0.9rem',
    };
    return (

        <Paper elevation={0}>
            <Accordion style={{marginBottom: '20px'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant="h5">Расписание</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <TableContainer>
                        <Table>
                            <TableHead>

                            </TableHead>
                            <TableBody>
                                {schedule.map((lesson) => (
                                    <TableRow key={lesson.id}>

                                        <TableCell><Typography variant="body1"
                                                               sx={cellStyles}>{lesson.dayOfWeekTranslate}</Typography></TableCell>
                                        <TableCell>
                                            <Box>
                                                <Typography variant="body1" sx={cellStyles}>{lesson.time}</Typography>
                                                <Typography variant="body1"
                                                            sx={cellStylesSubtitle}>{lesson.duration} мин.</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
}


const StudentAccordion: React.FC<{ students: Student[] }> = ({ students }) => {

const navigate = useNavigate();
const handleItemClick = (studentId: string) => {
    // Переход на страницу с передачей аргумента id
    navigate(`/group/student/${studentId}`);
};

  return(
      <Accordion defaultExpanded={true} style={{ marginBottom: '20px', border:'2px', borderColor:'primary' }}>
        <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">Ученики</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <List>
                {students.map((item) => (
                    <ListItem key={item.id}
                              onClick={() => handleItemClick(item.id as string)}
                    >
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
            </List>
        </AccordionDetails>
    </Accordion>
);
};

const TrainerAccordion: React.FC<{ trainers: Student[] }> = ({ trainers }) => (
    <Accordion style={{ marginBottom: '20px', border:'2px', borderColor:'primary' }}>
        <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">Тренеры</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <List>
                {trainers.map((item) => (
                    <ListItem key={item.id}>
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
            </List>
        </AccordionDetails>
    </Accordion>
);

const GroupDetailPage: React.FC = () => {
    const { id } = useParams< {id: string}>();
    const [groupDetail, setGroupDetail] = useState<Group | null>(null);
    const [schedule, setSchedule] = useState<Schedule[]>([]);
    const [students, setStudents] = useState<Student[]>([]);
    //const [trainers, setTrainers] = useState<Trainer[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchGroupData = async () => {
            try {
                const groupResponse = await ApiService.getGroup(id!);
                setGroupDetail(groupResponse);
                const scheduleResponse = await ApiService.getGroupSchedules(id!);
                setSchedule(scheduleResponse);
                const studentsResponse = await ApiService.getGroupStudents(id!);
                setStudents(studentsResponse.sort((a, b) => a.name!.localeCompare(b.name!))
                );
               // const trainersResponse = await ApiService.getTrainers(id);
               // setTrainers(trainersResponse);
                setLoading(false);
            } catch (error) {
                console.error('Ошибка при получении данных о группе:', error);
                setLoading(false);
            }
        };

        fetchGroupData();
    }, [id]);

    return (
        <div>
            <CommonHeader title="Группа" showBackButton={true} />

            {loading ? (
                <CircularProgressBar />
            ) : (
                <div style={{ margin: '6px' }}>
                    <GroupInfo groupDetail={groupDetail!} studentCount={students.length} />
                    {(groupDetail?.whatsapplink !=null)?( <Button startIcon={<WhatsAppIcon />} href={groupDetail?.whatsapplink as string}>WhatsApp</Button>):(<></>)}
                    {(groupDetail?.viberlink !=null)?(  <Button startIcon={<WifiCalling3TwoToneIcon />} href={groupDetail?.viberlink as string}>Viber</Button>):(<></>)}


                    <ScheduleAccordion schedule={schedule} />
                    <StudentAccordion students={students} />
                    {//  <TrainerAccordion trainers={trainers} />
                    }
                </div>
            )}
        </div>
    );
};

export default GroupDetailPage;
